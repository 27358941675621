import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import dynamicformReducer from '../components/dynamicForm/dynamicForm.slice';
import megaMenuReducer from '../newComponents/megaMenu.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['Form', 'megaMenu'],
};

const rootReducer = combineReducers({
  Form: dynamicformReducer,
  megaMenu: megaMenuReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);
export default store;
