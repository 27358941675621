import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

function getThirdPartFromUrl(url) {
  const parts = url.split('/');
  return parts[3];
}
function getFourthPartFromUrl(url) {
  const parts = url.split('/');
  return parts[4];
}

const MegaMenu = ({ _setLoadingFalse }) => {
  useEffect(() => {
    _setLoadingFalse();
  }, [_setLoadingFalse]);
  // const location = window.location
  // console.log('winwinwin',window.location)

  const data = useStaticQuery(graphql`
    query {
      allStrapiMenu(sort: { displayOrder: ASC }) {
        edges {
          node {
            apiUrl
            displayOrder
            title
            slug
          }
        }
      }
      allStrapiNewHeader {
        edges {
          node {
            blackBackgroundLogo {
              url
            }
            whiteBackgroundLogo {
              url
            }
            mobileBlackBackgroundLogo {
              url
            }
            mobileWhiteBackgroundLogo {
              url
            }
          }
        }
      }

      allArticlesForMegaMenu: allStrapiArticle(
        filter: { identifier: { ne: "news" } }
      ) {
        edges {
          node {
            slug
            excerpt
            title
            coverImage {
              url
            }
            identifier
            navigationUrl
            possible_paths {
              path
            }
          }
        }
      }
    }
  `);

  const [loc, setloc] = useState();
  const _currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setloc(getThirdPartFromUrl(window.location.href));
    }
    handleMenuLeave();
    setIsHovered(false);
  }, [_currentUrl]);

  const [currLocation, setCurrLocation] = useState('');
  const [bgClass, setBgClass] = useState('');
  const [bgClassMobile, setBgClassMobile] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [bgColorMobile, setBgColorMobile] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredChilds, setIsHoveredChilds] = useState({
    parent: '',
    child: '',
    val: false,
    index: 0,
    subChild: '',
  });

  // code to set white backgrund for other pages
  const [whiteBackground, setWhiteBackground] = useState(false);
  const [searchName, setSearchName] = useState('');
  useEffect(() => {
    const currentSlug =
      typeof window !== 'undefined'
        ? window.location.pathname.replace(/^\/|\/$/g, '')
        : '';
    const currentUrl =
      typeof window !== 'undefined' ? window.location.href : '';

    setCurrLocation(getThirdPartFromUrl(currentUrl));

    setBgClass('');
  }, []);

  // const location = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSearchName(getFourthPartFromUrl(window.location.href));
    }
  }, [_currentUrl]);

  useEffect(() => {
    const calculatedBgClass = whiteBackground ? 'bg-white' : '';
    setBgClass(calculatedBgClass);
  }, [whiteBackground]);

  // --------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const calculatedBgClass = bgColor ? `bg-${bgColor}` : '';
    setBgClass(calculatedBgClass);
  }, [bgColor]);

  useEffect(() => {
    const calculatedBgClass = bgColorMobile ? `bg-${bgColorMobile}` : '';
    setBgClassMobile(calculatedBgClass);
  }, [bgColorMobile]);

  const [activemenu, setActiveMenu] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [activesubMenu, setActivesubMenu] = useState(0);
  const [activesubsubMenu, setActivesubsubMenu] = useState(0);
  const [menushow, setMenushow] = useState(false);

  // mobile menu
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [iconOpen, setIconOpen] = useState(false);
  const [level, setLevel] = useState(0);

  //mobile menu

  const [category, setCategory] = useState([{ name: 'Industries' }]);
  const [_articles, set_Articles] = useState([]);

  const categorySelect = (
    categoryName,
    _parentPath,
    _childPath,
    _subChildPath,
    leval
  ) => {
    if (category.some((_category) => _category.name === categoryName)) {
      let _category = category.filter(
        (_categories) => _categories.name != categoryName
      );
      setCategory(_category);
    } else setCategory([{ name: categoryName }]);

    const uniqueChildren = _children.reduce((acc, current) => {
      const isDuplicate = acc.some(
        (item) => item.displayLabel === current.displayLabel
      );
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, []);

    // Set the firstChild state with the unique array
    let child = uniqueChildren.filter((child) => child.slug == _parentPath);

    if (leval === 1) {
      const _path = `${_parentPath}/${child[0]?.children[0]?.slug}/${child[0]?.children[0]?.children[0]?.slug}`;
      set_Articles(
        data?.allArticlesForMegaMenu?.edges?.filter((article) => {
          return article.node.possible_paths.some(
            (path) => path?.path == _path
          );
        })
      );
    } else {
      const _path = `${_parentPath}/${_childPath}/${_subChildPath}`;
      set_Articles(
        data?.allArticlesForMegaMenu?.edges.filter((article) => {
          return article.node.possible_paths.some(
            (path) => path.path === _path
          );
        })
      );
    }
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [readmore, setReadmore] = useState(false);

  //   calling apis for loading children
  const [_children, setChildren] = useState([]);

  useEffect(() => {
    // dispatch(resetState());
    data?.allStrapiMenu?.edges.map((menuData, menuindex) => {
      const apiUrl = menuData.node.apiUrl;
      const fetchData = async () => {
        if (apiUrl != null) {
          try {
            const response = await fetch(apiUrl, {
              headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_ACCESS_TOKEN}`,
              },
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setChildren((_children) => [..._children, ...jsonData]);
          } catch (error) {
            setError(error);
          } finally {
            if (menuindex == data.allStrapiMenu.edges.length - 1)
              setLoading(false);
            _setLoadingFalse();
          }
        }
        // setChildren(megaMenuData);
      };

      fetchData();
    });
  }, []);

  // first child
  const [firstChild, setFirstChild] = useState([]);

  useEffect(() => {
    if (typeof menuItems?.node?.title != undefined) {
      const uniqueChildren = _children.reduce((acc, current) => {
        const isDuplicate = acc.some(
          (item) => item.displayLabel === current.displayLabel
        );
        if (!isDuplicate) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Set the firstChild state with the unique array
      setFirstChild(
        uniqueChildren.filter(
          (child) => child.displayLabel == menuItems?.node?.title
        )
      );
    }
  }, []);

  useEffect(() => {
    if (typeof menuItems?.node?.title != undefined) {
      const uniqueChildren = _children.reduce((acc, current) => {
        const isDuplicate = acc.some(
          (item) => item.displayLabel === current.displayLabel
        );
        if (!isDuplicate) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Set the firstChild state with the unique array
      setFirstChild(
        uniqueChildren.filter(
          (child) => child.displayLabel == menuItems?.node?.title
        )
      );
    }
  }, [menuItems]);

  const showMore = (viewMore, menuequal, currentloc, menutitle) => {
    if (!viewMore) {
      return (
        <div
          onClick={() => {
            setReadmore((prev) => !prev);
          }}
          className='flex items-center cursor-pointer   text-[#374151] text-[16px] font-[500] leading-[150%] hover:text-[#3251BF] 
          hover:fill-[#3251BF]'
        >
          {/* <Link to='/blogs-list'> View more...</Link> */}
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              className='mt-[1px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.7071 12.7071C14.3166 13.0976 13.6834 13.0976 13.2929 12.7071L10 9.41421L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L9.29289 7.29289C9.68342 6.90237 10.3166 6.90237 10.7071 7.29289L14.7071 11.2929C15.0976 11.6834 15.0976 12.3166 14.7071 12.7071Z'
                fill={`${
                  bgClass == '' || bgColor === '[#1b1b1b]'
                    ? '#94A3B8'
                    : '#0E2D9D'
                }`}
              />
            </svg>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className='flex cursor-pointer  text-[#374151] text-[16px] font-[500] leading-[150%]  hover:text-[#3251BF]'
          onClick={() => {
            setReadmore((prev) => !prev);
          }}
        >
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              className='mt-[1px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z'
                fill={`${
                  bgClass == '' || bgColor === '[#1b1b1b]'
                    ? (currentloc === 'about-us' &&
                        menutitle.toUpperCase() === 'ABOUT US') ||
                      currentloc?.toUpperCase() === menutitle.toUpperCase()
                      ? '#FFFFFF'
                      : '#94A3B8'
                    : '#0F1A2A'
                }`}
              />
            </svg>
          </div>
        </div>
      );
    }
  };

  const handleMenuLeave = () => {
    setActiveMenu(null);
    setMenushow(false);
    setActivesubMenu(0);
    setActivesubsubMenu(0);
  };

  const [searchClicked, setSearchClicked] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleKeyPress = (event) => {
    if (
      event.key === 'Enter' &&
      searchValue.trim().length != 0 &&
      !searchValue.includes('/') &&
      !searchValue.includes('\\') &&
      !searchValue.startsWith('?') &&
      !searchValue.includes('%') &&
      encodeURI(searchValue) !== searchName
    ) {
      navigate(`/search/${searchValue}`, { state: { data: searchValue } });
    }
  };

  const searchRef = useRef(null);
  const mobileHeaderRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the header
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchClicked(false);
        setIconOpen(false);
        setBgColorMobile('');
        setBgColor('');
      }

      if (
        mobileHeaderRef.current &&
        !mobileHeaderRef.current.contains(event.target)
      ) {
        setIconOpen(false);
        setSearchClicked(false);
        setBgColorMobile('');
        // setBgColor('[#1b1b1b]');
      }
    };

    // Add event listener for clicks on the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef, mobileHeaderRef]);

  const searchIconPress = () => {
    if (
      searchValue.trim().length != 0 &&
      !searchValue.includes('/') &&
      !searchValue.includes('\\') &&
      !searchValue.startsWith('?') &&
      !searchValue.includes('%') &&
      encodeURI(searchValue) !== searchName
    ) {
      navigate(`/search/${searchValue}`, { state: { data: searchValue } });
    }
  };

  useEffect(() => {
    const handleWheel = (e) => {
      if (iconOpen || menushow) {
        e.preventDefault();
      }
    };

    if (iconOpen || menushow) {
      document.body.classList.add('disable-scroll');
      window.addEventListener('wheel', handleWheel, { passive: false });
    } else {
      document.body.classList.remove('disable-scroll');
      window.removeEventListener('wheel', handleWheel);
    }

    return () => {
      window.removeEventListener('wheel', handleWheel);
      document.body.classList.remove('disable-scroll');
    };
  }, [iconOpen, menushow]);

  const [insights, setInsights] = useState(false);

  function renderWithReplacement(label) {
    if (!label) return null;

    const parts = label.split(/tsworks/i);

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <span style={{ textTransform: 'none' }}>tsworks</span>
        )}
      </React.Fragment>
    ));
  }

  return (
    <>
      {/* <div className='flex relative w-full z-50 top-0 justify-center items-center pt-[8px] pb-[8px] text-[#165BE5] bg-[#f0f0f0] font-[Inter] font-[500] text-[14px] pl-[24px] pr-[24px]'>
        <svg
          width='16'
          height='16'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 2L1 21h22L12 2z'
            fill='none'
            stroke='#000000'
            stroke-width='2'
          />
          <line
            x1='12'
            y1='8'
            x2='12'
            y2='13'
            stroke='#000000'
            stroke-width='2'
          />
          <circle cx='12' cy='17' r='1' fill='#000000' />
        </svg>{' '}
        <div className='ml-[8px] '>We are updating our website.</div>
      </div> */}
      <div
        className={`font-[Inter] ${
          whiteBackground ? '' : 'absolute'
        } w-full z-50 ${loading ? 'hidden' : 'block'}  ${
          searchClicked ? 'xlmd:!bg-[#0F1A2A] xlmd:!h-[72px]' : ''
        } ${bgColor === 'bg-[#0F1A2A]' ? 'bg-[#0F1A2A]' : ''}`}
      >
        {/* desktop view */}
        <div
          className={`relative z-50 ${[bgClass]} ${
            bgClass == '' ? '' : 'bg-[#0F1A2A]'
          }  ${searchClicked ? ' xlmd:mt-[0px]' : ''}`}
        >
          {!searchClicked ? (
            <div
              className={`hidden xlmd:block  w-full font-[Inter] max-w-[1440px] sxl:mx-auto `}
            >
              {/* Parent menu */}
              {/* give a border bottom for this */}
              <div
                className={`flex justify-between py-[24px] !box-border pl-[104px] pr-[104px]  ${
                  bgClass == '' ? '' : 'bg-[#0F1A2A]'
                } `}
              >
                <Link to='/' className=''>
                  <img
                    src={
                      bgClass === '' || bgColor === '[#1b1b1b]'
                        ? data?.allStrapiNewHeader?.edges[0].node
                            .blackBackgroundLogo.url
                        : data?.allStrapiNewHeader?.edges[0].node
                            .whiteBackgroundLogo.url
                    }
                    className='h-[23.605px]  shrink-0'
                  />
                </Link>
                <div className='relative'>
                  <div className='flex gap-[32px] absolute top-0 right-0 '>
                    {/* hidden first child */}
                    {data?.allStrapiMenu.edges.map((menuData, menuindex) => (
                      <div
                        key={menuindex}
                        onMouseEnter={() => {
                          if (!loading) {
                            setBgColor(
                              menuData.node.slug === 'insights'
                                ? ''
                                : '[#1b1b1b]'
                            );
                            setInsights(
                              menuData.node.slug === 'insights' ? true : false
                            );

                            setActiveMenu(menuindex);
                            setActivesubsubMenu(0);
                            setActivesubMenu(0);
                            setMenushow(
                              menuData.node.slug === 'insights' ? false : true
                            );
                            setMenuItems(menuData);
                            categorySelect(
                              menuData.node.title,
                              menuData.node.slug,
                              firstChild[0]?.children[0]?.slug,
                              firstChild[0]?.children[0]?.children[0]?.slug,
                              1
                            );
                          }
                        }}
                        onMouseLeave={() => setInsights(false)}
                        className={`flex items-center justify-center gap-[8px]              
                           text-[16px] leading-[150%] font-[500]
                           text-${
                             bgClass === '' || bgColor === '[#1b1b1b]'
                               ? '[#94A3B8]'
                               : '#0F1A2A'
                           } whitespace-nowrap`}
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className={` ${
                            menuData.node.slug != 'insights'
                              ? activemenu === menuindex
                                ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50 '
                                : (
                                    loc === 'about-us'
                                      ? menuData.node.title === 'About Us'
                                        ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50 '
                                        : ''
                                      : loc?.toUpperCase() ===
                                        menuData.node.title.toUpperCase()
                                  )
                                ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50  text-[#F6F8FC]'
                                : ''
                              : activemenu === menuindex && insights
                              ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50 '
                              : (
                                  loc === 'about-us'
                                    ? menuData.node.title === 'About Us'
                                      ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50'
                                      : ''
                                    : loc?.toUpperCase() ===
                                      menuData.node.title.toUpperCase()
                                )
                              ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50  text-[#F6F8FC] '
                              : ''
                          }`}
                        >
                          <Link to={`/${menuData.node.slug}`}>
                            {menuData.node.title}
                          </Link>
                        </div>

                        <div>
                          {
                            <>
                              {menuData.node.slug != 'insights' &&
                                showMore(
                                  activemenu !== menuindex,
                                  activemenu === menuindex,
                                  loc,
                                  menuData.node.title
                                )}
                            </>
                          }
                        </div>
                      </div>
                    ))}

                    {/* contact us  */}
                    <div
                      onMouseLeave={() => {
                        handleMenuLeave();
                        whiteBackground ? setBgColor('[#FFF]') : setBgColor('');
                      }}
                      onMouseEnter={() => {
                        if (!loading) {
                          // setBgColor('[#FFF]');
                          setBgColor('');
                          setActiveMenu(4);
                          setMenushow(false);
                        }
                      }}
                      className={`flex items-center justify-center gap-[8px]            
                      text-[16px] leading-[150%] font-[500] 
                      text-${
                        bgClass === '' || bgColor === '[#1b1b1b]'
                          ? '[#94A3B8]'
                          : '#0F1A2A'
                      } whitespace-nowrap`}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        className={` ${
                          activemenu === 4
                            ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50 text-[#94A3B8] '
                            : loc?.toUpperCase() === 'CONTACT-US'
                            ? ' underline underline-offset-8 decoration-[#165BE5] decoration-2 z-50  text-[#F6F8FC]'
                            : ' '
                        }`}
                      >
                        <Link to={`/contact-us`}>Contact Us</Link>
                      </div>
                    </div>

                    {/* hiding search */}
                    {/* {loc !== 'search' && (
                      <div
                        onMouseLeave={() => {
                          handleMenuLeave();
                          whiteBackground
                            ? setBgColor('[#FFF]')
                            : setBgColor('');
                        }}
                        onMouseEnter={() => {
                          if (!loading) {
                            // setBgColor('[#FFF]');
                            setActiveMenu(5);
                            setMenushow(false);
                          }
                        }}
                        onClick={() => {
                          setSearchClicked(true);
                        }}
                        className='cursor-pointer hover:stroke-slate-400 stroke-white transition-colors duration-300 ease-in-out'
                      >
                        <svg
                          className=''
                          width='21'
                          height='20'
                          // width='36'
                          // height='36'
                          // viewBox='0 0 20 20'
                          viewBox='0 0 21 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M19.5645 19L13.5645 13M15.5645 8C15.5645 11.866 12.4304 15 8.56445 15C4.69846 15 1.56445 11.866 1.56445 8C1.56445 4.13401 4.69846 1 8.56445 1C12.4304 1 15.5645 4.13401 15.5645 8Z'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>

              {menushow && !loading && (
                <div className='absolute z-10 w-full left-0 h-[100vh] bg-[#0F1A2A] bg-opacity-60'>
                  <div
                    className='bg-[#0F1A2A]'
                    onMouseLeave={() => {
                      handleMenuLeave();
                      {
                        whiteBackground ? setBgColor('[#FFF]') : setBgColor('');
                      }
                    }}
                  >
                    <div className='gap-[30px] pl-[104px] pr-[104px]  w-full pt-[32px] pb-[48px] text-[16px] font-[500] leading-[150%] max-w-[1440px] sxl:mx-auto'>
                      <div
                        className='cursor-pointer mt-6 inline-block'
                        onMouseEnter={() => {
                          setIsHovered(true);
                        }}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <Link
                          to={`/${menuItems?.node?.slug}`}
                          className='text-[18px] font-[600] text-[#FFFFFF] leading-[21.6px] capitalize'
                        >
                          {/* <HomeButton
                            menu={true}
                            title={menuItems?.node?.title}
                            animation={isHovered}
                          /> */}
                          {menuItems?.node?.title}
                        </Link>
                      </div>

                      <div className='flex'>
                        {/* 2nd leval child */}
                        <div className='flex mt-[36px] gap-[64px] w-full'>
                          {firstChild?.map((submenu, submenuindex) => (
                            <>
                              {submenu.displayLabel === menuItems.node.title &&
                                submenu.children?.map(
                                  (_firstchild, firstchildIndex) => (
                                    <div
                                      className='inline-block'
                                      key={firstchildIndex}
                                      onMouseEnter={() => {
                                        setIsHoveredChilds({
                                          parent: _firstchild.slug,
                                          child: _firstchild.slug,
                                          val: true,
                                          index: firstchildIndex,
                                          subChild: _firstchild.slug,
                                        });
                                        setActivesubMenu(firstchildIndex);
                                        setActivesubsubMenu(0);
                                        categorySelect(
                                          _firstchild.displayLabel,
                                          firstChild[0]?.slug,
                                          _firstchild?.slug,
                                          _firstchild?.children[0]?.slug,
                                          2
                                        );
                                      }}
                                      onMouseLeave={() => {
                                        setIsHoveredChilds({
                                          child: _firstchild.slug,
                                          val: false,
                                          index: firstchildIndex,
                                          subChild: _firstchild.slug,
                                        });
                                      }}
                                    >
                                      <div className='text-[10px] font-[500] leading-[12px ]  inline-block text-[#F6F8FC]'>
                                        <Link className='opacity-[0.5] uppercase text-[10px] font-[500] leading-[12px ]'>
                                          {' '}
                                          {renderWithReplacement(
                                            _firstchild?.displayLabel
                                          )}
                                        </Link>

                                        <div className='mt-[24px]'>
                                          {_firstchild?.children?.map(
                                            (subchild, _index) => (
                                              <>
                                                {subchild.displayLabel !==
                                                  'Dummy Page' && (
                                                  <div
                                                    className='my-4'
                                                    key={_index}
                                                    onMouseEnter={() => {
                                                      setIsHoveredChilds({
                                                        parent:
                                                          _firstchild.slug,
                                                        child: _firstchild.slug,
                                                        val: true,
                                                        index: _index,
                                                        subChild: subchild.slug,
                                                      });
                                                      setActivesubsubMenu(
                                                        _index
                                                      );
                                                      categorySelect(
                                                        subchild.displayLabel,
                                                        firstChild[0].slug,
                                                        firstChild[0]?.children[
                                                          activesubMenu
                                                        ].slug,
                                                        subchild.slug,
                                                        3
                                                      );
                                                    }}
                                                    onMouseLeave={() => {
                                                      setIsHoveredChilds({
                                                        parent:
                                                          _firstchild.slug,
                                                        child: _firstchild.slug,
                                                        val: false,
                                                        index: _index,
                                                        subChild: subchild.slug,
                                                      });
                                                    }}
                                                  >
                                                    <div
                                                      className={`text-[14px] font-[400] leading-[22.4px] inline-block text-[#94A3B8] opacity-100 hover:text-[#F6F8FC] capitalize  hover:underline underline-offset-2 `}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <Link
                                                        className='inline-block'
                                                        to={
                                                          firstChild[0]
                                                            ?.slug === 'careers'
                                                            ? `/${firstChild[0]?.slug}/${subchild.slug}`
                                                            : `/${firstChild[0]?.slug}/${subchild.slug}`
                                                          // : `/${firstChild[0]?.slug}/${firstChild[0].children[activesubMenu].slug}/${subchild.slug}` just commented
                                                        }
                                                      >
                                                        {renderWithReplacement(
                                                          subchild?.displayLabel
                                                        )}
                                                        <div
                                                          className={`h-[2px] ${
                                                            isHoveredChilds.child ===
                                                              _firstchild.slug &&
                                                            isHoveredChilds.val &&
                                                            isHoveredChilds.index ===
                                                              _index &&
                                                            isHoveredChilds.subChild ===
                                                              subchild.slug
                                                              ? 'w-full'
                                                              : 'w-0'
                                                          }`}
                                                        ></div>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div ref={searchRef}>
              {/* Desktop View */}
              <div
                className={`xlmd:block hidden w-full font-[Inter] sxl:w-[1232px] sxl:mx-auto
             sxl:px-0 lmd:px-[104px] px-[24px] h-[72px] !box-border
                  bg-[#0F1A2A] pt-[16px] ${
                    loc === 'search' ? 'fixed top-0' : ''
                  }`}
                header
              >
                <div className='flex'>
                  <div className='w-full'>
                    <input
                      value={searchValue}
                      onKeyDown={handleKeyPress}
                      placeholder='Type to search'
                      type='text'
                      onChange={(event) => {
                        setSearchValue(event.currentTarget.value);
                      }}
                      className='w-full h-full pt-[8px] font-[Inter] bg-[#0F1A2A] focus:border-transparent focus:outline-none leading-[48px] placeholder-[#d9d9d9] text-white placeholder:text-[18px] placeholder:font-[400] sm:text-[18px] text-[24px]'
                    />
                  </div>

                  <svg
                    onClick={() => {
                      setSearchClicked(false);
                      setIconOpen(false);
                      setBgColorMobile('');
                      setSearchValue('');
                    }}
                    className='cursor-pointer mt-[11.5px]'
                    width='21'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M19.0898 6.36377L6.36192 19.0917' stroke='white' />
                    <path d='M7.07031 5.65674L19.7982 18.3847' stroke='white' />
                  </svg>

                  <svg
                    onClick={() => {
                      searchIconPress();
                    }}
                    className='mt-4 ml-4'
                    width='21'
                    height='20'
                    viewBox='0 0 36 36'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M30 30L21.9161 21.9161M21.9161 21.9161C24.104 19.7282 25.3332 16.7608 25.3332 13.6666C25.3332 10.5724 24.104 7.60498 21.9161 5.41707C19.7282 3.22916 16.7608 2 13.6666 2C10.5724 2 7.60498 3.22916 5.41707 5.41707C3.22916 7.60498 2 10.5724 2 13.6666C2 16.7608 3.22916 19.7282 5.41707 21.9161C7.60498 24.104 10.5724 25.3332 13.6666 25.3332C16.7608 25.3332 19.7282 24.104 21.9161 21.9161V21.9161Z'
                      stroke='#fff'
                      stroke-width='3'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
                <div className=''>
                  <div className='sm:border-b-[2px] sm:border-[#fff]'></div>
                  {/* border-[#0052cc]  */}
                </div>
              </div>

              {/* Mobile View */}
              <div
                className={`xlmd:hidden  w-full font-[Inter] sxl:w-[1232px] sxl:mx-auto
            sxl:px-0 lmd:px-[104px] px-[24px] !h-[40x] my-auto flex  !box-border
              pt-[16px] ${loc === 'search' ? '' : ''}`}
                header
                //ref={searchRef}
              >
                <div className='my-auto mr-[8px]'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    onClick={() => {
                      setSearchClicked(false);
                      // setIconOpen(false);
                      // setBgColorMobile('');
                      // setSearchValue('');
                    }}
                  >
                    <path
                      d='M15 19L8 12L15 5'
                      stroke='white'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
                <div className='flex bg-[#0F1A2A] w-[100%] h-[40px] rounded-[8px]'>
                  <div className='w-full ml-[12px]'>
                    <input
                      style={{ caretColor: '#F6F8FC' }}
                      value={searchValue}
                      onKeyDown={handleKeyPress}
                      placeholder='Search'
                      type='text'
                      onChange={(event) => {
                        setSearchValue(event.currentTarget.value);
                      }} //text-[#F6F8FC]
                      className='w-full h-full rounded-[8px]  text-[#FFFFFF] font-[Inter] bg-[#0F1A2A]  focus:border-transparent focus:outline-none leading-[48px] placeholder-[#344B6D] placeholder:text-[16px] placeholder:font-[400] font-[400] sm:text-[16px]'
                    />
                  </div>

                  {searchValue && (
                    <div className='my-auto gap-[8px] mr-[12px]'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        onClick={() => {
                          //setSearchClicked(false);
                          setIconOpen(false);
                          setBgColorMobile('');
                          setSearchValue('');
                        }}
                      >
                        <path
                          d='M6 18L18 6M6 6L18 18'
                          stroke='white'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </div>
                  )}
                </div>
                <div className=''>
                  <div className='sm:border-b-[2px] sm:border-[#fff]'></div>
                  {/* border-[#0052cc]  */}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* mobile view */}
        {!searchClicked && (
          <div
            ref={mobileHeaderRef}
            className={`xlmd:hidden  block w-full font-[Inter] z-50  ${
              iconOpen ? 'fixed h-[100vh]' : ''
            } ${[bgClassMobile]}`}
          >
            {/* //give a border fo this */}
            <div
              className={`flex justify-between items-center  sxl:px-0 lmd:px-[104px] px-[24px] h-[72px] py-[24px] !box-border  ${
                bgClassMobile == ''
                  ? ''
                  : 'bg-[#0F1A2A]  border-b-[1px] border-[#27364B]'
              }`}
            >
              {/* This is tasworkslogo */}
              <div className='h-6'>
                <Link
                  to={'/'}
                  onClick={() => {
                    setIconOpen(false);
                    setBgColorMobile('');
                  }}
                >
                  <img
                    className={`!h-6 !w-[100.7px]`}
                    src={
                      data?.allStrapiNewHeader?.edges[0].node
                        .mobileBlackBackgroundLogo.url
                    }
                  />
                </Link>
              </div>

              <div className='flex gap-[24px]'>
                {/* This is Search icon */}
                <div
                  onMouseLeave={() => {
                    handleMenuLeave();
                    whiteBackground ? setBgColor('[#FFF]') : setBgColor('');
                  }}
                  onMouseEnter={() => {
                    if (!loading) {
                      // setBgColor('[#FFF]');
                      setActiveMenu(5);
                      setMenushow(false);
                    }
                  }}
                  onClick={() => {
                    setSearchClicked(true);
                  }}
                  className={`'cursor-pointer my-auto hover:stroke-slate-400 stroke-white transition-colors duration-300 ease-in-out'`}
                >
                  {/* <svg
                    className={`${loc === 'search' ? 'sm:hidden' : ''}`}
                    width='21'
                    height='20'
                    viewBox='0 0 21 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M19.5645 19L13.5645 13M15.5645 8C15.5645 11.866 12.4304 15 8.56445 15C4.69846 15 1.56445 11.866 1.56445 8C1.56445 4.13401 4.69846 1 8.56445 1C12.4304 1 15.5645 4.13401 15.5645 8Z'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg> */}
                </div>

                {/* This is hanburgermenu */}
                <div className='py-2'>
                  <div className='relative'>
                    {bgClassMobile === '' ? (
                      <div
                        onClick={() => {
                          setIconOpen(true);
                          setBgColorMobile('[#0F1A2A]');
                          // setBgColorMobile('[#FFF]');
                        }}
                      >
                        <svg
                          className='cursor-pointer'
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            d='M4 6H20M4 12H20M4 18H20'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className=''>
                        <svg
                          onClick={() => {
                            setIconOpen(false);
                            setBgColorMobile('');
                          }}
                          className='cursor-pointer'
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            d='M6 18L18 6M6 6L18 18'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    )}
                  </div>{' '}
                </div>
              </div>
            </div>

            {iconOpen && (
              <div className='z-50'>
                <>
                  {data.allStrapiMenu.edges.map((menulink) => (
                    <div className=' text-white' key={menulink.id}>
                      {/* hidding mobile view menu */}
                      <div
                        className={`${
                          level === 1 || level === 2 ? 'hidden' : 'flex'
                        } bg-[#0F1A2A]  w-full p sxl:px-0 lmd:px-[104px] px-[24px] py-6  items-center`}
                      >
                        {level === 0 && (
                          <div className='flex justify-between w-full'>
                            <Link
                              to={`/${menulink.node.slug}`}
                              className='w-full'
                              onClick={() => {
                                setIconOpen(false);
                                setBgColorMobile('');
                              }}
                            >
                              <div className='w-full cursor-pointer'>
                                {menulink.node.title}
                              </div>
                            </Link>
                            {heading === menulink.node.title ? (
                              <svg
                                className='cursor-pointer'
                                onClick={() => {
                                  heading !== menulink.node.title
                                    ? setHeading(menulink.node.title)
                                    : setHeading('');
                                  setSubHeading('');
                                  setLevel(1);
                                  setMenuItems(menulink);
                                }}
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <path
                                  d='M15 19L8 12L15 5'
                                  stroke='#9CA3AF'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            ) : (
                              <>
                                {menulink.node.slug != 'insights' && (
                                  <svg
                                    className='cursor-pointer'
                                    onClick={() => {
                                      heading !== menulink.node.title
                                        ? setHeading(menulink.node.title)
                                        : setHeading('');
                                      setSubHeading('');
                                      setLevel(1);
                                      setMenuItems(menulink);
                                    }}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                  >
                                    <path
                                      d='M9 5L16 12L9 19'
                                      stroke='#9CA3AF'
                                      strokeWidth='1.5'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                  </svg>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        {level === 1 && heading === menulink.node.title && (
                          <div className='flex justify-between w-full'>
                            {menulink.node.title}
                            {heading === menulink.node.title ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <path
                                  d='M15 19L8 12L15 5'
                                  stroke='#9CA3AF'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <path
                                  d='M9 5L16 12L9 19'
                                  stroke='#9CA3AF'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            )}
                          </div>
                        )}
                      </div>

                      <div
                        className={`${
                          heading === menulink.node.title
                            ? ' xlmd:hidden lmd:px-[104px]'
                            : 'hidden'
                        }`}
                      >
                        <div>
                          {(level === 1 || level === 2) && (
                            <div
                              onClick={() => {
                                setLevel(0);
                                setHeading('');
                                setSubHeading('');
                              }}
                              className='text-[#a2a2a0] font-[Inter] font[600] flex py-6 gap-4 pl-5 cursor-pointer w-[250px]'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <path
                                  fillRule='evenodd'
                                  clipRule='evenodd'
                                  d='M12 21.5999C17.3019 21.5999 21.6 17.3018 21.6 11.9999C21.6 6.69797 17.3019 2.3999 12 2.3999C6.69806 2.3999 2.39999 6.69797 2.39999 11.9999C2.39999 17.3018 6.69806 21.5999 12 21.5999ZM12.8485 9.24843C13.3172 8.7798 13.3172 8.02 12.8485 7.55137C12.3799 7.08275 11.6201 7.08275 11.1515 7.55137L7.55147 11.1514C7.08284 11.62 7.08284 12.3798 7.55147 12.8484L11.1515 16.4484C11.6201 16.9171 12.3799 16.9171 12.8485 16.4484C13.3172 15.9798 13.3172 15.22 12.8485 14.7514L11.2971 13.1999H15.6C16.2627 13.1999 16.8 12.6626 16.8 11.9999C16.8 11.3372 16.2627 10.7999 15.6 10.7999H11.2971L12.8485 9.24843Z'
                                  fill='#a2a2a0'
                                />
                              </svg>
                              Main Menu
                            </div>
                          )}
                        </div>
                        {/* second level */}
                        {firstChild[0]?.children?.map((slinks) => (
                          <div key={slinks.id}>
                            {(level === 1 ||
                              (level === 2 &&
                                subHeading === slinks.displayLabel)) && (
                              <div
                                className={` ${
                                  level === 2 ? 'hidden' : 'flex'
                                } py-5 pl-5 md:pr-0 pr-5 flex justify-between items-center bg-[#0F1A2A]`}
                              >
                                {menuItems.node.slug === 'about-us' ? (
                                  <Link
                                    className='w-full'
                                    onClick={() => {
                                      setIconOpen(false);
                                      setBgColorMobile('');
                                    }}
                                    to={`/${menuItems.node.slug}/#${slinks.slug}`}
                                  >
                                    <div className='w-full'>
                                      {renderWithReplacement(
                                        slinks.displayLabel
                                      )}
                                    </div>
                                  </Link>
                                ) : (
                                  <div className='w-full text-[#F6F8FC] opacity-[0.5]'>
                                    {renderWithReplacement(slinks.displayLabel)}
                                  </div>
                                )}
                                {slinks.children && (
                                  <div
                                    onClick={() => {
                                      subHeading !== slinks.displayLabel
                                        ? setSubHeading(slinks.displayLabel)
                                        : setSubHeading('');
                                      setLevel(2);
                                    }}
                                  >
                                    {level === 2 &&
                                    subHeading === slinks.displayLabel ? (
                                      <svg
                                        className='cursor-pointer'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                      >
                                        <path
                                          d='M15 19L8 12L15 5'
                                          stroke='#9CA3AF'
                                          strokeWidth='1.5'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        className='cursor-pointer'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                      >
                                        <path
                                          d='M9 5L16 12L9 19'
                                          stroke='#9CA3AF'
                                          strokeWidth='1.5'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                        />
                                      </svg>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}

                            <div
                              className={`${
                                subHeading === slinks.displayLabel
                                  ? ' bg-[#0F1A2A] xlmd:hidden'
                                  : 'hidden'
                              }`}
                            >
                              <div
                                onClick={() => {
                                  setLevel(1);
                                  setSubHeading('');
                                }}
                                className='text-[#a2a2a0]   font-[500] flex py-6 gap-4 pl-5'
                              >
                                <svg
                                  className='cursor-pointer'
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M12 21.5999C17.3019 21.5999 21.6 17.3018 21.6 11.9999C21.6 6.69797 17.3019 2.3999 12 2.3999C6.69806 2.3999 2.39999 6.69797 2.39999 11.9999C2.39999 17.3018 6.69806 21.5999 12 21.5999ZM12.8485 9.24843C13.3172 8.7798 13.3172 8.02 12.8485 7.55137C12.3799 7.08275 11.6201 7.08275 11.1515 7.55137L7.55147 11.1514C7.08284 11.62 7.08284 12.3798 7.55147 12.8484L11.1515 16.4484C11.6201 16.9171 12.3799 16.9171 12.8485 16.4484C13.3172 15.9798 13.3172 15.22 12.8485 14.7514L11.2971 13.1999H15.6C16.2627 13.1999 16.8 12.6626 16.8 11.9999C16.8 11.3372 16.2627 10.7999 15.6 10.7999H11.2971L12.8485 9.24843Z'
                                    fill='#a2a2a0'
                                  />
                                </svg>
                                {slinks.displayLabel}
                              </div>
                              {slinks.children?.map((slink) => (
                                <Link
                                  // to={`/${menuItems.node.slug}/${slinks.slug}/${slink.slug}`}
                                  to={
                                    firstChild[0]?.slug === 'careers'
                                      ? `/${firstChild[0]?.slug}/${slink.slug}`
                                      : `/${firstChild[0]?.slug}/${slink.slug}`
                                  }
                                  onClick={() => {
                                    setIconOpen(false);
                                    setBgColorMobile('');
                                  }}
                                  key={slink.id}
                                >
                                  <div className='py-5 pl-5 text-base hover:underline underline-offset-2 hover:text-[#FFFFFF] text-[#94A3B8] capitalize'>
                                    {renderWithReplacement(slink?.displayLabel)}
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
                {level === 0 && (
                  <>
                    <div className='text-white w-full py-6 bg-[#0F1A2A]'>
                      <Link
                        to={`/contact-us`}
                        className='w-full  items-center py-6 '
                        onClick={() => {
                          setIconOpen(false);
                          setBgColorMobile('');
                        }}
                      >
                        <div className='w-full  sxl:px-0 lmd:px-[104px] px-[24px]'>
                          Contact Us
                        </div>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MegaMenu;
